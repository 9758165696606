<template>
  <div class="pt-16">
    <div
      class="w-full h-50vh md:h-25vh lg:h-50vh px-4 md:px-0 py-12 mx-auto text-left bg-fixed bg-contain bg-no-repeat flex items-center justify-end"
      :style="`background-image: url(${bgImage})`"
    >
      <!-- <div class="w-5/12 py-12 px-4 md:px-0 text-4xl font-semibold">
        <div class="w-max p-4 text-secondary">Preschool</div>
      </div> -->
    </div>
    <div
      class="md:w-768 lg:w-1024 mx-auto py-12 px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
    >
      <div class="w-full text-textBlack text-left">
        <h1 class="mb-2 text-2xl text-primary font-semibold">
          Fun and Effective English ​for Emergent Bilingual Students
        </h1>
        <p class="text-base text-justify">
          Your students will develop the language base that is a prerequisite
          for effective speaking and literacy development. With an early
          emphasis on oral skills, vocabulary, and phonemic awareness, students
          will be ready to excel in spelling, sentence construction, and
          sentence reading exercises.​
        </p>
        <h1 class="mt-10 mb-0 text-xl text-primary font-semibold text-center">
          Preschool Course
        </h1>
        <ul
          class="flex flex-wrap md:flex-nowrap justify-between text-textBlack"
        >
          <li class="w-full md:w-3/12 bg-softGrey py-4 px-6 mt-2 md:mt-6">
            <h5 class="font-semibold text-pulpBlue text-center text-lg">
              3 CEFR Levels​
            </h5>
            <p class="mt-2 pt-2 text-base border-t border-primary">
              Learners are placed in one of 3 levels. Each level has 2 parts and
              can be completed in 30 study hours.​ neo's AI does the tracking to
              free you up for more teaching time​​.
            </p>
          </li>
          <li
            class="w-full md:w-3/12 bg-softGrey py-4 px-6 md:mx-2 mt-2 md:mt-6"
          >
            <h5 class="font-semibold text-pulpBlue text-center text-lg">
              Unit Structure​​
            </h5>
            <p class="mt-2 pt-2 text-base border-t border-primary">
              Includes songs, vocabulary, grammar,​ phonics, and games that
              engage students as they learn new material. At higher levels, we
              introduce speech recognition practice​.
            </p>
          </li>
          <li
            class="w-full md:w-3/12 bg-softGrey py-4 px-6 md:mr-2 mt-2 md:mt-6"
          >
            <h5 class="font-semibold text-pulpBlue text-center text-lg">
              Mastery Tests​
            </h5>
            <p class="mt-2 pt-2 text-base border-t border-primary">
              neo’s Mastery Tests confirm a student’s grasp of the material
              within the course. Passing the test automatically gives them acces
              to more advanced levels.
            </p>
          </li>
          <li class="w-full md:w-3/12 bg-softGrey py-4 px-6 mt-2 md:mt-6">
            <h5 class="font-semibold text-pulpBlue text-center text-lg">
              Level Tests​​
            </h5>
            <p class="mt-2 pt-2 text-base border-t border-primary">
              After completing the final Mastery Test, learners take a
              comprehensive test covering key CEFR language requirements and
              concepts.​
            </p>
          </li>
        </ul>
      </div>
    </div>
    <ul class="mb-20 flex flex-col items-center justify-center">
      <li class="mb-4">
        <a
          class="bg-softBlue text-secondary px-4 py-1 mr-1 cursor-pointer underline font-light"
          href="https://cms.nexgenenglishonline.co/uploads/files/A1%20Nexgen%20Website%20Media%20Lib/Brochures%20NW/neoJr%2B%20Course%20Leaflet.pdf"
          target="_blank"
        >
          Download more info
        </a>
      </li>
      <li>
        <a
          class="bg-softBlue text-secondary px-4 py-1 mr-1 cursor-pointer underline font-light"
          href="https://cms.nexgenenglishonline.co/uploads/files/A1%20Nexgen%20Website%20Media%20Lib/Brochures%20NW/neoJr%2B%20Course%20Leaflet.pdf"
          target="_blank"
        >
          neoJr+ Skill Level Correlation
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bgImageUrl: require("@/assets/images/icons/3-preschool.jpg"),
      isMobile: window.innerWidth <= 768,
      mobileImageUrl: require("@/assets/images/mobile/3..preschool-720x1280-C.jpg"),
      desktopImageUrl: require("@/assets/images/icons/3-preschool.jpg"),
    };
  },
  computed: {
    bgImage() {
      return this.isMobile ? this.mobileImageUrl : this.desktopImageUrl;
    },
  },
};
</script>

<style>
</style>